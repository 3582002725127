import { createAsyncThunk } from '@reduxjs/toolkit';
import { TaskService } from '@src/services/calendar-event/task.service';
import { IGetOneTaskPay, IGetOneTaskResponce } from './types';

export const getOneTask = createAsyncThunk<IGetOneTaskResponce, IGetOneTaskPay>('getone/task', async ({ id }, thunkApi) => {
	try {
		const responce = await TaskService.getOneTask(id);

		return { data: responce } as IGetOneTaskResponce;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
