import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { getOneNote } from './actions';

const initialState: InitialState = {
	loading: {
		get: false,
		post: false,
		put: false,
		delete: false,
	},
	error: null,
	notes: null,
	note: {
		id: 0,
		title: '',
		description: '',
		time: '',
		repeat_rule: 0,
		repeat_exceptions: [],
		reminders: [],
		repeat: '',
		until_date: null,
		created_at: '',
	},
};

export const noteSlice = createSlice({
	name: 'noteSlice',
	initialState,
	reducers: {},
	extraReducers: ({ addCase }) => {
		addCase(getOneNote.fulfilled, (state, { payload }) => {
			console.log(payload);
			state.note = payload.data;
		});
	},
});

export default noteSlice.reducer;
