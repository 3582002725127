import 'firebase/messaging';
import { onMessage } from 'firebase/messaging';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { messaging, onMessageListener } from './config';
import Routes from './routes';

function App() {
	const [registrationId, setRegistationId] = useState('');
	const [companyTitle, setCompanyTitle] = useState(JSON.parse(localStorage.getItem('currentCompany') as string)?.title || '');
	const history = useHistory();

	useEffect(() => {
		onMessageListener().then(payload => {
			console.log(payload);
		});

		onMessage(messaging, payload => {
			toast.success(payload.notification?.title, { position: 'top-right' });
		});
	}, []);

	return (
		<div className='App'>
			<Routes />
			<ToastContainer />
		</div>
	);
}

export default App;
