import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { getOneTask } from './actions';

const initialState: InitialState = {
	loading: {
		get: false,
		post: false,
		put: false,
		delete: false,
	},
	error: null,
	task: null,
	tasks: null,
	currentTask: {
		id: 0,
		title: '',
		description: '',
		yaqm: '',
		performer: {
			id: 0,
			full_name: '',
			image: '',
		},
		leader: {
			id: 0,
			full_name: '',
			image: '',
		},
		creator: 0,
		start_time: '',
		end_time: '',
		files: [],
		status: {
			id: 0,
			title: '',
		},
		importance: '',
		participants: [],
		spectators: [],
		functional_group: [],
		is_pinned: false,
		created_at: '',
		last_updated: '',
		is_done: false,
		folder: {
			id: 0,
			title: '',
		},
		parent: null,
		repeat: '',
		repeat_rule: 0,
		until_date: null,
		internal_status: '',
		comments: [],
		is_editable: true,
		is_time_changeable: false,
		commentaries: [],
		updates_history: [],
		is_calendar: false,
	},
};

export const taskSlice = createSlice({
	name: 'taskSlice',
	initialState,
	reducers: {},
	extraReducers: ({ addCase }) => {
		addCase(getOneTask.fulfilled, (state, { payload }) => {
			state.currentTask = payload.data;
		});
	},
});

export default taskSlice.reducer;
