import { Switch } from "react-router-dom";
import { routes } from "./config";
import AsRoute from "./route";
import { useSelector } from "react-redux";
import { selectCompany } from "@src/store/reducer-and-action/company/company";

const Routes = () => {
  const state = useSelector(selectCompany);
  // console.log(state.selectedCompany);

  return (
    <Switch>
      {routes(state.selectedCompany).map((route) => (
        <AsRoute key={route.path} {...route} />
      ))}
    </Switch>
  );
};

export default Routes;
