import { BaseUrl } from '@src/data/baseUrl';
import axios, { AxiosError } from 'axios';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookies from 'universal-cookie';

const firebaseConfig = {
	apiKey: 'AIzaSyD9WKc1YZdYgOFVV2ttv-1F9u5c-zEmVMg',
	authDomain: 'pm-tez.firebaseapp.com',
	projectId: 'pm-tez',
	storageBucket: 'pm-tez.appspot.com',
	messagingSenderId: '862347199914',
	appId: '1:862347199914:web:442290b7a435f8332fd7d3',
	measurementId: 'G-4BH7XCVWTL',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// getRequestPermission
export const requestPermission = async () => {
	const cookies = new Cookies();
	try {
		const permission = await Notification.requestPermission();

		if (permission === 'granted') {
			const token = await getToken(messaging, {
				vapidKey: 'BJKfURGMLI4zAZhcXPq4RDnKny18mX6engbpTnhkf02DjLOVx_4f_Qiyv3kl0hqkuIc-qGyDh8-4r4OdKXqxYpA',
			});

			if (token) {
				const user = cookies.get('user_profile_info');

				axios.get(BaseUrl + `notification/device/${token}`)
					.then(data => {
						console.log(data.data);
					})
					.catch((err: AxiosError) => {
						if (err.response?.status === 404) {
							axios.post('https://pm.backoffice.uz/api/v1/notification/device/', {
								user: user.id,
								registration_id: token,
							})
								.then(data => {})
								.catch((err: AxiosError) => {
									console.log(err.message);
								});
						}
					});
			}
		} else {
			console.log('denied permission');
		}
	} catch (error) {
		console.log(error);
	}
};

requestPermission();

// Listen messages
export const onMessageListener = () =>
	new Promise(resolve => {
		onMessage(messaging, payload => {
			resolve(payload);
		});
	});
