import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { getOneAssambly } from './actions';

const initialState: InitialState = {
	loading: {
		get: false,
		post: false,
		put: false,
		delete: false,
	},
	error: null,
	assamblys: null,
	assambly: {
		id: 0,
		creator: {
			id: 0,
			full_name: '',
			image: '',
		},
		partner_ins: [
			{
				id: 0,
				full_name: '',
				image: '',
			},
		],
		partner_out: '',
		description: '',
		start_time: '',
		end_time: '',
		members: [],
		files: [],
		address: '',
		importance: '',
		repeat: '',
		repeat_rule: 0,
		reminders: [],
		until_date: null,
		created_at: '',
		status: '',
	},
};

export const assamblySlice = createSlice({
	name: 'assamblySlice',
	initialState,
	reducers: {},
	extraReducers: ({ addCase }) => {
		addCase(getOneAssambly.fulfilled, (state, { payload }) => {
			console.log(payload);
			state.assambly = payload.data;
		});
	},
});

export default assamblySlice.reducer;
