import { IChat } from './chat.t';
import { TypeStore } from './../../../config';
import { createSelector } from "@reduxjs/toolkit";


export const getMessages = createSelector(
    (store: TypeStore) => store.ui.chat,
    (chat: IChat)=>{
        return chat.messageList
    }
)