import { ACCESS_TOKEN, baseURL } from '@src/config/site.config';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

const axiosService = axios.create({
	baseURL: baseURL,
});

const setToken = (config: AxiosRequestConfig) => {
	if (!config?.headers?.authorization) {
		if (ACCESS_TOKEN && config.headers) {
			config.headers.authorization = `Bearer ${ACCESS_TOKEN}`;
		}
	}
	return config;
};

const requestMapper = (config: AxiosRequestConfig) => {
	return config;
};

const responseMapper = (response: AxiosResponse): Promise<AxiosResponse> => {
	return Promise.resolve(response.data);
};

const errorHandler = (error: AxiosError): Promise<never> => {
	if (error.response?.status === 401) {
		// logout();
	}

	return Promise.reject(error?.response?.data);
};

axiosService.interceptors.request.use(setToken);
axiosService.interceptors.request.use(requestMapper);
axiosService.interceptors.response.use(responseMapper, errorHandler);

export { axiosService };
