import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "@src/types/auth";
import { IAuth } from "./auth.t";
import Cookies from "universal-cookie";
import { TypeStore } from "@src/store";

const cookies = new Cookies();

const getItems = () => {
  cookies.get("user_profile_info");
  if (cookies.get("user_profile_info") !== undefined) {
    return cookies.get("user_profile_info");
  } else {
    return {};
  }
};

const {
  id = 0,
  email = "",
  phone_number = "",
  image = "",
  first_name = "",
  last_name = "",
  role = "",
} = getItems();

const initialState: IAuth = {
  user: {
    first_name: "",
    last_name: "",
    password: "",
    phone_number: "",
  },

  currentUser: {
    id,
    email,
    phone_number,
    image,
    first_name,
    last_name,
    role,
  },
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state: IAuth, { payload }: PayloadAction<IUser>) => {
      state.user.first_name = payload.first_name;
      state.user.last_name = payload.last_name;
      state.user.password = payload.password;
      state.user.phone_number = payload.phone_number;
    },
  },
});

export const selectUser = (state: TypeStore) => state.auth;

export const { setUser } = slice.actions;

export * from "./auth.s";
export * from "./auth.t";
export default slice.reducer;
