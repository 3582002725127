import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "@src/data/baseUrl";
import { TypeStore } from "@src/store/config";
import Cookies from "universal-cookie";
import  {IInitialState, IQuickIdeaFolder, IQuickIdeaListByRate, IQuickIdeaBox, IQuickIdeaBoxFinished, IQuickIdea} from "./quickIdea.t"


const cookies = new Cookies();

const initialState: IInitialState = {
    quick_idea_folder: [],
    quick_idea: [],
    quick_idea_list_by_rate: [],
    quick_idea_box: [],
    quick_idea_box_finished: [],


    loading: false,
    folderId: null,
    ideaId: null,
}

export const getQuickIdeaFolder = createAsyncThunk(
    "quickIdeaFolder/getQuickIdeaFolder",
    async (thunkAPI) => {
        let quickIdeaFolderUrl = `${BaseUrl}idea/idea-folder/`;
        const requestOptions = {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaFolderUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const getQuickIdeaRead = createAsyncThunk(
    "quickIdea/getQuickIdea",
    async (id: number, thunkAPI) => {
        let quickIdeaUrl = `${BaseUrl}idea/idea/${id}/`;
        const requestOptions = {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const postQuickIdeaFolder = createAsyncThunk(
    "quickIdeaFolder/postQuickIdeaFolder",
    async (title: string, thunkAPI) => {
        let quickIdeaFolderUrl = `${BaseUrl}idea/idea-folder/`;
        const requestOptions = {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
            body: JSON.stringify({
                title: title,
            })
      };
      const res = await fetch(quickIdeaFolderUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const postQuickIdeaRate = createAsyncThunk(
    "quickIdeaRate/postQuickIdeaRate",
    async ({id, rate}: {id: number, rate: number | null}, thunkAPI) => {
        let quickIdeaRateUrl = `${BaseUrl}idea/idea-rate/${id}/`;
        const requestOptions = {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
            body: JSON.stringify({
                rate: rate,
            })
      };
      const res = await fetch(quickIdeaRateUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const updateQuickIdeaFolder = createAsyncThunk(
    "quickIdeaFolderUpdate/updateQuickIdeaFolder",
    async ({ title, id }: { title: string, id: number }, thunkAPI) => {
        let quickIdeaFolderUpdateUrl = `${BaseUrl}idea/idea-folder-update/${id}/`;
        const requestOptions = {
            method: "PUT",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
            body: JSON.stringify({
                title: title,
            })
      };
      const res = await fetch(quickIdeaFolderUpdateUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const updateIdeaBox = createAsyncThunk(
    "quickIdeaBoxUpdate/updateQuickIdeaBox",
    async ({ id, title, description, folderId }: { id: number, title: string, description: string, folderId: number }, thunkAPI) => {
        let quickIdeaBoxUpdateUrl = `${BaseUrl}idea/idea-update/${id}/`;
        const requestOptions = {
            method: "PUT",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
            body: JSON.stringify({
                title: title,
                description: description,
                folder: folderId,
                to_idea_box: true
            })
      };
      const res = await fetch(quickIdeaBoxUpdateUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const createIdeaToBox = createAsyncThunk(
    "quickIdeaBoxCreate/createQuickIdeaToBox",
    async ({title, description}: { title: string, description: string}, thunkAPI) => {
        let quickIdeaBoxCreateUrl = `${BaseUrl}idea/idea/`;
        const requestOptions = {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
            body: JSON.stringify({
                title: title,
                description: description,
                to_idea_box: true
            })
      };
      const res = await fetch(quickIdeaBoxCreateUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const postQuickIdea = createAsyncThunk(
    "quickIdea/postQuickIdea",
    async ({title, description, folderId}: { title: string, description: string, folderId: string}, thunkAPI) => {
        let quickIdeaUrl = `${BaseUrl}idea/idea/`;
        const requestOptions = {
            method: "POST",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
            body: JSON.stringify({
                title: title,
                description: description,
                folder: folderId
            })
      };
      const res = await fetch(quickIdeaUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const deleteQuickIdeaFolder = createAsyncThunk(
    "quickIdeaFolder/deleteQuickIdeaFolder",
    async (id: number, thunkAPI) => {
        let quickIdeaFolderUrl = `${BaseUrl}idea/idea-folder-delete/${id}/`;
        const requestOptions = {
            method: "DELETE",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaFolderUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const deleteQuickIdea = createAsyncThunk(
    "quickIdea/deleteQuickIdea",
    async (id: number, thunkAPI) => {
        let quickIdeaUrl = `${BaseUrl}idea/idea-delete/${id}/`;
        const requestOptions = {
            method: "DELETE",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const getQuickIdeaListByRate = createAsyncThunk(
    "quickIdeaListByRate/getQuickIdeaListByRate",
    async (id: number, thunkAPI) => {
        let quickIdeaListByRateUrl = `${BaseUrl}idea/idea-list-by-rate/${id}/`;
        const requestOptions = {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaListByRateUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const getQuickIdeaBox = createAsyncThunk(
    "quickIdeaBox/getQuickIdeaBox",
    async (thunkAPI) => {
        let quickIdeaBoxUrl = `${BaseUrl}idea/idea-box/`;
        const requestOptions = {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaBoxUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);

export const getQuickIdeaBoxFinished = createAsyncThunk(
    "quickIdeaBoxFinished/getQuickIdeaBoxFinished",
    async (thunkAPI) => {
        let quickIdeaBoxFinishedUrl = `${BaseUrl}idea/idea-box-finished/`;
        const requestOptions = {
            method: "GET",
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access_token")}`,
            },
      };
      const res = await fetch(quickIdeaBoxFinishedUrl, requestOptions).then(
        (data) => data.json()
      ).catch(()=>'error');
      return res;
    }
);


const slice = createSlice({
    name: 'Idea',
    initialState,
    reducers: {
        removeQuickIdeaFolder: (state, action) => {
            let todo: any = state.quick_idea_folder 
            let todo1 = todo.data.filter((item: any) => item.id !== action.payload)
            todo.data = todo1
            state.quick_idea_folder = todo
        },
        removeQuickIdea: (state, action) => {
            
            let todo: any = state.quick_idea_list_by_rate 
            let todo1 = todo.ideas.filter((item: any) => item.id !== action.payload)
            todo.ideas = todo1
            state.quick_idea_list_by_rate = todo
        },
        addQuickIdeaFolder: (state, action) => {
            
        }, 
        changeFolderId: (state, action) => {
            state.folderId = action.payload
        },
        changeIdeaId: (state, action) => {
            state.ideaId = action.payload
        },
        changeFolderName: (state, action) => {
            
                let todo: any = state.quick_idea_folder
                const folder = todo.data.map((item: any) => {
                    if(state.folderId === item.id){
                      item = {...item, title: action.payload}
                    }
                    return item
                  })
                 
            todo.data = [...folder]
            state.quick_idea_folder = todo
        },
        removeIdeaListByRate: ( state, action) => {
            let todo: any = state.quick_idea_list_by_rate
            let todo1 = todo.ideas.filter((item: any) => item.id !== action.payload)
            todo.ideas = todo1
            state.quick_idea_list_by_rate = todo
        }

    },
    extraReducers: (builder) => {
        builder.addCase(getQuickIdeaFolder.pending, (state) => {
            state.loading = true;
        }).addCase(
            getQuickIdeaFolder.fulfilled,
            (state, action: PayloadAction<Array<IQuickIdeaFolder>>) => {
              state.loading = false;
              state.quick_idea_folder = action.payload;
        }).addCase(
            getQuickIdeaFolder.rejected, (state) => {
                state.loading = true;
                state.quick_idea_folder = []
            }
        );
        builder.addCase(postQuickIdeaFolder.pending, (state) => {
            state.loading = true
        }).addCase(postQuickIdeaFolder.fulfilled, (state, action: PayloadAction<Array<IQuickIdeaFolder>>) => {
            state.loading = true
            let item: any = action.payload
            let todo: any = state.quick_idea_folder 
            let todo1 = [...todo.data, item.data]
            todo.data = todo1
            state.quick_idea_folder = todo
            console.log(state);
            
        })
        builder.addCase(getQuickIdeaListByRate.pending, (state) => {
            state.loading = true;
        }).addCase(
            getQuickIdeaListByRate.fulfilled,
            (state, action: PayloadAction<Array<IQuickIdeaListByRate>>) => {
              state.loading = false;
              state.quick_idea_list_by_rate = action.payload;
            //   console.log('done', action.payload);
        }).addCase(
            getQuickIdeaListByRate.rejected, (state, action) => {
                state.loading = true;
                state.quick_idea_list_by_rate = []
            }
        );
        builder.addCase(getQuickIdeaBox.pending, (state) => {
            state.loading = true;
        }).addCase(
            getQuickIdeaBox.fulfilled,
            (state, action: PayloadAction<Array<IQuickIdeaBox>>) => {
              state.loading = false;
              state.quick_idea_box = action.payload;
            //   console.log('done', action.payload);
        }).addCase(
            getQuickIdeaBox.rejected, (state, action) => {
                state.loading = true;
                state.quick_idea_box = []
            }
        );
        builder.addCase(getQuickIdeaBoxFinished.pending, (state) => {
            state.loading = true;
        }).addCase(
            getQuickIdeaBoxFinished.fulfilled,
            (state, action: PayloadAction<Array<IQuickIdeaBoxFinished>>) => {
              state.loading = false;
              state.quick_idea_box_finished = action.payload;
            //   console.log('done', action.payload);
        }).addCase(
            getQuickIdeaBoxFinished.rejected, (state, action) => {
                state.loading = true;
                state.quick_idea_box_finished = []
            }
        );
        builder.addCase(getQuickIdeaRead.pending, (state) => {
            state.loading = true;
        }).addCase(
            getQuickIdeaRead.fulfilled,
            (state, action: PayloadAction<Array<IQuickIdea>>) => {
              state.loading = false;
              state.quick_idea = action.payload;
            //   console.log('done', action.payload);
        }).addCase(
            getQuickIdeaRead.rejected, (state, action) => {
                state.loading = true;
                state.quick_idea = []
            }
        );
        
    }
}) as any

export const selectQuickPlan = (state: TypeStore) => state.plan

export const { removeQuickIdeaFolder, changeFolderId, changeFolderName, removeQuickIdea, changeIdeaId, removeIdeaListByRate } = slice.actions

export default slice.reducer;