import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer-and-action";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type TypeStore = ReturnType<typeof store.getState>;
