import { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IRoute } from './config';
import { useSelector } from 'react-redux';
import { selectCompany } from '@src/store/reducer-and-action/company/company';

const AsRoute = (route: IRoute) => {
	const { selectedCompany } = useSelector(selectCompany);


	return (
		<Suspense fallback={route.fallback}>
			<Route
				path={route.path}
				render={props =>
					route.redirect ? (
						<Redirect {...props} to={route.redirect} />
					) : route.private && selectedCompany !== null ? (
						<Redirect {...props} to={`/chats/company=${selectedCompany?.id}`} />
					) : (
						route.component && <route.component routes={route.routes} />
					)
				}
			/>
		</Suspense>
	);
};

export default AsRoute;
