import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChat } from "./chat.t";
import { BaseUrl } from "@src/data/baseUrl";
import Cookies from "universal-cookie";
import { TypeStore } from "@src/store";
import { ICurrentReplyMessage } from "@components/Layouts/chat/correspondence-panel/correspondences/daily-chat/message/types-message";

const initialState: IChat = {
  companyChats: [],
  messageList: [],
  messageListContainer: [], // shu yerda sms larni saqlayapman
  isLoading: false,
  chatsCategoryActiveId: 0,
  currentChatInfo: {
    id: 0,
    image: "",
    members: [],
    title: "",
    project: null,
  },
  currentReplyMessage: {
    action: "chat.group.send_message",
    data: {
      chat_id: 0,
      isReply: false,
      replyUserName: "",
      answer_for: 0,
      message: "",
      file: null,
      message_type: "text_with_media",
    },
  },
  currentEditMessage: {
    isEdit: false,
    action: "chat.group.edit_message",
    data: {
      message_id: 0,
      message: "",
    },
  },
};

export const getChat = createAsyncThunk(
  "chat/getChat",
  async (restUrl: string, thunkAPI) => {
    let chatUrl = `${BaseUrl}${restUrl}`;

    const cookies = new Cookies();

    const requestOptions = {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const response = await fetch(chatUrl, requestOptions);
    const data = await response.json();
    // if (data.code === "token_not_valid") {
    //   console.log(`data`, data);
    // }

    return data.data;
  }
);

export const getEachChatMessages = createAsyncThunk(
  "messages/getEachChatMessages",
  async (id: string, thunkAPI) => {
    let chatUrl = `${BaseUrl}chat/chat-group-retrieve/${id}/`;

    const cookies = new Cookies();

    const requestOptions = {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const response = await fetch(chatUrl, requestOptions);
    const data = await response.json();

    // console.log(`chat/chat-group-retrieve/`, data);
    return data;
  }
);

export const getEachChatMessagesByPage = createAsyncThunk(
  "messages/getEachChatMessagesByPage",
  async (restUrl: string, thunkAPI) => {
    let chatUrl = `${BaseUrl}${restUrl}`;

    const cookies = new Cookies();

    const requestOptions = {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const response = await fetch(chatUrl, requestOptions);
    const data = await response.json();

    const owner = cookies.get("user_profile_info");
    const ownerName = `${owner.first_name} ${owner.last_name}`;
    // console.log(`user_profile_info`, cookies.get("user_profile_info"));

    return data.results.map((item: any) => {
      const {
        answer_for,
        chat_id,
        creator,
        files,
        id,
        is_read,
        meeting,
        message,
        note,
        message_type,
        task,
        time_created,
      } = item;

      const { full_name } = creator;

      return {
        ...item,
        byMe: full_name === ownerName,
        chat_id,
        id,
        is_read,
        answer_for,
        files,
        meeting,
        note,
        task,
        creator,
        message: message,
        time: time_created?.split(" ")[1] || "",
        type: message_type,
        byWhom: {
          position: "",
          avatar:
            "https://images.unsplash.com/photo-1626586066636-a1523dd2141b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzJ8fGF2YXRhcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
          name: full_name,
        },
      };
    });
  }
);

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setMessageList: (state: IChat, { payload }: PayloadAction<any[]>) => {
      state.messageList = [...payload];
    },
    setChatsCategoryActiveId: (
      state: IChat,
      { payload }: PayloadAction<number>
    ) => {
      state.chatsCategoryActiveId = payload;
    },
    setCompanyChatsEmpty: (state: IChat) => {
      state.companyChats = [];
    },
    setMessageListContainer: (state: IChat, action: any) => {
      state.messageListContainer = [
        action.payload,
        ...state.messageListContainer,
      ];
    },
    // @ts-ignore
    setCurrentReplyMessage: (state: IChat, action: ICurrentReplyMessage) => {
      // @ts-ignore
      state.currentReplyMessage = action.payload;
    },
    setCurrentEditMessage: (state: IChat, action: any) => {
      state.currentEditMessage = action.payload;
    },
    setCurrentChatInfo: (state: IChat, action: any) => {
      state.currentChatInfo = action.payload;
    },
    setEditMessageList: (state: IChat, action) => {
      state.messageListContainer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChat.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getChat.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.isLoading = false;
        state.companyChats = action.payload || [];
      }
    );
    builder.addCase(getChat.rejected, (state, action) => {
      state.isLoading = false;
      state.companyChats = [];
      //   state.error = action.error.message;
    });

    builder.addCase(getEachChatMessages.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getEachChatMessages.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.currentChatInfo = action.payload || [];
      }
    );
    builder.addCase(getEachChatMessages.rejected, (state, action) => {
      state.isLoading = false;
      state.currentChatInfo = {};
    });

    builder.addCase(getEachChatMessagesByPage.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getEachChatMessagesByPage.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.isLoading = false;
        state.messageListContainer = action.payload || [];
      }
    );
    builder.addCase(getEachChatMessagesByPage.rejected, (state, action) => {
      state.isLoading = false;
      state.messageListContainer = [];
    });
  },
});

export const selectChat = (state: TypeStore) => state.ui.chat; // to get chat state

export const {
  setMessageList,
  setChatsCategoryActiveId,
  setCompanyChatsEmpty,
  setMessageListContainer,
  setCurrentReplyMessage,
  setCurrentChatInfo,
  setCurrentEditMessage,
  setEditMessageList,
} = slice.actions;

export * from "./chat.s";
export * from "./chat.t";
export default slice.reducer;
