export const formatDate = (date: Date) => {
	let formattedDate;
	let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	let month = date?.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	let year = date.getFullYear();
	formattedDate = day + '.' + month + '.' + year;
	return formattedDate;
};

export const differentInDays = (from: Date, to: Date) => {
	let differentTime = to.getTime() - from.getTime();
	return Math.round(differentTime / (1000 * 3600 * 24));
};

export const formatDateWithHour = (date: Date) => {
	const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
	const year = date.getFullYear();
	const hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
	const minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
	return formattedDate;
};

export const convertStringToDate = (date: string): Date => {
	const [datePart, timePart] = date.split(' ');
	const [year, month, day] = datePart.split('-').map(Number);
	const [hour, minute] = timePart.split(':').map(Number);

	const convertedDate = new Date(Date.UTC(year, month - 1, day, hour, minute));

	return convertedDate;
};
