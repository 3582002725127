import { AnyAction, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import {
  IDirection,
  IEachStaff,
  IGoalsFolder,
  IInitialState,
  INotification,
} from "./company.t";
import { BaseUrl } from "@src/data/baseUrl";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { ICurrentCompany } from "@components/Layouts/companies/types-companies";
import { TypeStore } from "@src/store/config";
import { calcEndTime } from "@components/common/nav-bar/components/CreateTaskChildren/helper";
import { INewNote } from "@components/common/nav-bar/components/Reminder/Reminder";
import { Dispatch, SetStateAction } from "react";
import { IColumn } from "@components/Layouts/tasks/add-column-modal/add-drag-drop-modal";
import { createApiThunk } from "./api";

const cookies = new Cookies();

const initialState: IInitialState = {
  company_direction: [],
  sub_company_direction: [],
  all_staff: [],
  goals_folder: [],
  goals: [],
  events: [],
  meetings: [],
  tasks: [],
  notificationList: [],
  projects: [],
  folders: [],
  datingList: [],

  loading: false,
  secondLoading: false,
  structureOfCompany: {},
  error: {},
  selectedCompany:
    JSON.parse(localStorage.getItem("currentCompany") as string) || null,

  taskerId: null,
  directorId: null,
  title: "",
  start_time: new Date(),
  end_time: calcEndTime(new Date()),
  repeat: 0,

  // Modal title
  goalTitle: "",
};

export const getCompanyDirection = createAsyncThunk(
  "direction/getCompanyDirection",
  async (thunkAPI) => {
    let companyDirectionUrl = `${BaseUrl}company/company-direction/`;

    const res = await fetch(companyDirectionUrl).then((data) => data.json());
    return res;
  }
);

// https://pm.backoffice.uz/api/v1/notification/device/id/test
export const getTestNotification = createAsyncThunk(
  "notification/getTestNotification",
  async (registration_id: string, thunkAPI) => {
    let companyDirectionUrl = `${BaseUrl}notification/device/${registration_id}/test`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(companyDirectionUrl, requestOptions).then((data) =>
      data.json()
    );
    // console.log(`res`, res);
    return res;
  }
);

export const getCompanySubDirection = createAsyncThunk(
  "sub_direction/getCompanySubDirection",
  async (id: number, thunkAPI) => {
    let companySubDirectionUrl = `${BaseUrl}company/company-subdirection/?direction_id=${id}`;
    const res = await fetch(companySubDirectionUrl).then((data) => data.json());

    return res;
  }
);

export const postCompany = createAsyncThunk(
  "company/postCompany",
  async (currentCompanyObj: ICurrentCompany, thunkAPI) => {
    let companyUrl = `${BaseUrl}user/change-company`;
    const cookies = new Cookies();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(currentCompanyObj),
    };

    const response = await fetch(companyUrl, requestOptions);
    const data = await response.json();

    return data;
  }
);

export const getAllStaff = createAsyncThunk(
  "staff/getAllStaff",
  async (url: string, thunkAPI) => {
    let staffUrl =
      url.length === 0 ? `${BaseUrl}company/short-all-staffs/` : url;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(staffUrl, requestOptions).then((data) =>
      data.json()
    );
    return res.data;
  }
);

export const getSctuctureCompany = createAsyncThunk(
  "company/getCurrentCompany",
  async (thunkAPI) => {
    let staffUrl = `${BaseUrl}company/short-structure/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(staffUrl, requestOptions).then((data) =>
      data.json()
    );

    // console.log(`getSctuctureCompany`, !!res?.detail);

    if (!!res?.detail) {
      toast.error(res?.detail);
      cookies.remove("access_token");
      cookies.remove("refresh_token");
      // localStorage.removeItem
    }

    return res.data;
  }
);

export const postNewTask = createAsyncThunk(
  "company/postNewTask",
  async (newTaskObj: any, { dispatch }) => {
    let newTaskUrl = `${BaseUrl}task/task/`;

    const { handleClose, ...restObj } = newTaskObj;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(restObj),
    };

    const response = await fetch(newTaskUrl, requestOptions);
    const data = await response.json();

    console.log(data);
    if (data.success) {
      toast.success(`Muvaffaqiyatli yaratilgan`);
      dispatch(setTitle(""));
      dispatch(getFolders());
      handleClose();
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    // return data;
  }
);

export const getGoalsFolder = createAsyncThunk(
  "goals/getGoalsFolder",
  async (thunkAPI) => {
    let goalsUrl = `${BaseUrl}goal/goal-folder/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(goalsUrl, requestOptions).then((data) =>
      data.json()
    );

    // console.log(`goals res`, res);

    if (!!res?.detail) {
      toast.error(res?.detail);
      cookies.remove("access_token");
      cookies.remove("refresh_token");
    }

    return res.data;
  }
);

export const postNewFolder = createAsyncThunk(
  "folder/postNewFolder",
  async (goalObj: any, { dispatch }) => {
    let goalUrl = `${BaseUrl}goal/goal-folder/`;

    const { setTestModal, ...restObj } = goalObj;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(restObj),
    };

    const response = await fetch(goalUrl, requestOptions);
    const data = await response.json();

    // console.log(`data`, data);

    if (data.success) {
      toast.success(`Muvaffaqiyatli yaratildi`);
      dispatch(setGoalTitle(""));
      setTestModal(false);
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    return data;
  }
);

export const getGoals = createAsyncThunk("goals/getGoals", async (thunkAPI) => {
  let goalsUrl = `${BaseUrl}goal/goal/`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.get("access_token")}`,
    },
  };

  const res = await fetch(goalsUrl, requestOptions).then((data) => data.json());

  // console.log(`goals res`, res);

  if (!!res?.detail) {
    toast.error(res?.detail);
    // cookies.remove("access_token");
    // cookies.remove("refresh_token");
  }

  return res.data;
});

export const postNewProject = createAsyncThunk(
  "project/postNewProject",
  async (projectObj: any, { dispatch }) => {
    let projectUrl = `${BaseUrl}project/project/`;

    const { closeModalClearInputs, ...restObj } = projectObj;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(restObj),
    };

    const response = await fetch(projectUrl, requestOptions);
    const data = await response.json();

    // console.log(`data`, data);

    if (data.success) {
      toast.success(`Muvaffaqiyatli yaratildi`);
      dispatch(setGoalTitle(""));
      closeModalClearInputs();
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    return data;
  }
);

// TODO working
export const postNewMeeting = createAsyncThunk(
  "meeting/postNewMeeting",
  async (meetingObj: AnyAction, { dispatch }) => {
    let meetingUrl = `${BaseUrl}meeting/meeting/`;

    // const { closeModalClearInputs, ...restObj } = projectObj;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(meetingObj),
    };

    const response = await fetch(meetingUrl, requestOptions);
    const data = await response.json();

    console.log(`data`, data);
    if (data.success) {
      // close modal
    }

    // if (data.success) {
    //   toast.success(`Muvaffaqiyatli yaratildi`);
    //   dispatch(setGoalTitle(""));
    //   closeModalClearInputs();
    // } else if (data.success === false && data.error.length > 0) {
    //   data.error.forEach((item: any) => {
    //     const { error, key } = item;
    //     toast.error(`${error} => ${key}`);
    //   });
    // }

    return data;
  }
);

export const postNewNote = createAsyncThunk(
  "note/postNewNote",
  async (noteObj: INewNote, { dispatch }) => {
    let noteUrl = `${BaseUrl}notes/note/`;

    const { reSetInputs, ...restObj } = noteObj;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(restObj),
    };

    const response = await fetch(noteUrl, requestOptions);
    const data = await response.json();

    // console.log(`data`, data);

    if (data.success) {
      toast.success(`Muvaffaqiyatli yaratildi`);
      reSetInputs();
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    return data;
  }
);

export const getPeriodBy = createAsyncThunk(
  "calendar/getPeriodBy",
  async (restUrl: string, thunkAPI) => {
    let goalsUrl = `${BaseUrl}${restUrl}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(goalsUrl, requestOptions).then((data) =>
      data.json()
    );

    // red #ef466f
    // green #0ad6a1
    // blue #0881d9
    // orange #f57b39

    const colorsObj: Record<string, string> = {
      note: "#ef466f",
      dating: "#0881d9",
      meeting: "#f57b39",
      task: "#0ad6a1",
    };

    return res?.data?.map((item: any) => ({
      ...item,
      start: new Date(item.start_time),
      end: new Date(item.end_time),
      color: colorsObj[item.type],
    }));
  }
);

export const postNewTaskFolder = createAsyncThunk(
  "newFolder/postNewTaskFolder",
  async (
    newTaskFolder: { title: string; handleClose: () => void },
    { dispatch }
  ) => {
    let taskFolderUrl = `${BaseUrl}task/task-folder/`;

    const { handleClose } = newTaskFolder;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(newTaskFolder),
    };

    const response = await fetch(taskFolderUrl, requestOptions);
    const data = await response.json();

    console.log(`data`, data);

    if (data.success) {
      toast.success(`Muvaffaqiyatli yaratildi`);
      handleClose();
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    return data.data[0];
  }
);

export const getTasks = createAsyncThunk("task/getTasks", async (thunkAPI) => {
  let taskUrl = `${BaseUrl}task/task-folder-list/`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.get("access_token")}`,
    },
  };

  const res = await fetch(taskUrl, requestOptions).then((data) => data.json());

  return res;
});

export const getTaskInfo = createAsyncThunk(
  "task/getTaskInfo",
  async (id: number, thunkAPI) => {
    let goalsUrl = `${BaseUrl}task/task-retrieve/${id}/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(goalsUrl, requestOptions).then((data) =>
      data.json()
    );
    console.log(`res`, res);
    // return res?.data?.map((item: any) => ({
    //   ...item,
    //   start: item.start_time,
    //   end: item.end_time,
    // }));
  }
);

export const deleteTaskFolder = createAsyncThunk(
  "task/deleteTaskFolder",
  async (
    {
      id,
      closeModal,
    }: { id: number; closeModal: Dispatch<SetStateAction<boolean>> },
    { dispatch }
  ) => {
    let goalsUrl = `${BaseUrl}task/task-folder-delete/${id}/`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    await fetch(goalsUrl, requestOptions)
      .then((data) => {
        // console.log(`response`, data);
        if (data.ok) {
          closeModal(false);
          toast.success(`Deleted successfully`);
          // dispatch an action to delete current folder from UI
          dispatch(setTasks(id));
        }
        return data.json();
      })
      .then((data) => {
        if (data.success === false) {
          toast.error(data.message);
        }
        console.log(`res if`, data);
      });
  }
);

export const patchTaskToDrop = createAsyncThunk(
  "patchTaskStatus/patchTaskToDrop",
  async (id: number, { dispatch }) => {
    let taskUrl = `${BaseUrl}task/task-update/${id}/`;

    // const { handleClose } = newTaskFolder;
    const taskObj = {
      status: id,
    };

    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(taskObj),
    };

    const response = await fetch(taskUrl, requestOptions);
    const data = await response.json();

    // console.log(`data`, data);

    if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    return data;
  }
);

export const getTacticPlanList = createAsyncThunk(
  "list/getTacticPlanList",
  async (thunkAPI) => {
    // let goalsUrl = `${BaseUrl}task/task-retrieve/${id}/`;
    let tacticPlanListUrl = `${BaseUrl}tactic-plan/tactic-plan-list/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(tacticPlanListUrl, requestOptions).then((data) =>
      data.json()
    );
    console.log(`res`, res);
    // return res?.data?.map((item: any) => ({
    //   ...item,
    //   start: item.start_time,
    //   end: item.end_time,
    // }));
  }
);

export const postTaskColumn = createAsyncThunk(
  "column/postTaskColumn",
  async (newTaskFolder: IColumn, { dispatch }) => {
    const { handleClose, id, ...restObj } = newTaskFolder;

    const urlObj = {
      taskFolderStatus: `${BaseUrl}task/task-status/`,
      updateTaskStatus: `${BaseUrl}task/task-status-update/${id}/`,
    };

    const requestOptions = {
      method: id === null ? "POST" : "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(restObj),
    };

    const response = await fetch(
      id === null ? urlObj["taskFolderStatus"] : urlObj["updateTaskStatus"],
      requestOptions
    );
    const data = await response.json();

    // console.log(`data`, data);

    if (data.success || response.status === 200) {
      dispatch(getTasks());
      toast.success(data.message);
      handleClose();
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    return data.data[0];
  }
);

export const getNotifications = createAsyncThunk(
  "notification/getNotifications",
  async (navigate: () => void, thunkAPI) => {
    let tacticPlanListUrl = `${BaseUrl}company/notifications/?page=3&limit=15`;
    const dispatch = thunkAPI.dispatch;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(tacticPlanListUrl, requestOptions).then((data) =>
      data.json()
    );
    // console.log(`res`, res?.data.notifications_list);

    if (res.code === "token_not_valid") {
      dispatch(postRefreshToken(navigate));
      // console.log("this is notification");
    }

    return res?.data.notifications_list;
  }
);

export const postRefreshToken = createAsyncThunk(
  "refresh/postRefreshToken",
  async (navigate: () => void, thunkAPI) => {
    let refreshUrl = `${BaseUrl}user/token/refresh`;

    const refreshObj = {
      refresh: cookies.get("refresh_token"),
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("refresh_token")}`,
      },
      body: JSON.stringify(refreshObj),
    };

    const response = await fetch(refreshUrl, requestOptions);
    const data = await response.json();

    if (data.detail === "Token is invalid or expired") {
      navigate();
    }

    if (!!data.access) {
      cookies.set("access_token", data?.access);
      // cookies.set("refresh_token", data?.data?.refresh_token);
      // console.log(`data`, data);
    }

    if (data.success) {
      toast.success(`Muvaffaqiyatli yaratilgan`, data.message);
      // dispatch(setTitle(""));
      // handleClose();
    } else if (data.success === false && data.error.length > 0) {
      data.error.forEach((item: any) => {
        const { error, key } = item;
        toast.error(`${error} => ${key}`);
      });
    }

    // return data;
  }
);

export const getProjectList = createAsyncThunk(
  "project/getProjectList",
  async (thunkAPI) => {
    const url = `${BaseUrl}project/project-list/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(url, requestOptions).then((data) => data.json());
    return res.data;
  }
);

export const getFolders = createAsyncThunk(
  "folders/getFolders",
  async (thunkAPI) => {
    const url = `${BaseUrl}task/task-folder-list/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(url, requestOptions).then((data) => data.json());
    // console.log(res);
    return res;
  }
);

export const deleteColumn = createAsyncThunk(
  "column/deleteColumn",
  async (id: number, thunkAPI) => {
    const url = `${BaseUrl}task/task-status-delete/${id}/`;

    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    fetch(url, requestOptions).then((res) => {
      if (res.ok) {
        // thunkAPI.dispatch(getFolders());
        thunkAPI.dispatch(deleteTaskContainer(id));
        window.location.reload();
      }
    });
  }
);

// https://pm.backoffice.uz/api/v1/user/add-favourite

export const fetchDatingList = createApiThunk({
  name: "list/fetchDatingList",
  endpoint: `dating/dating-list/`,
  onSuccess: () => {},
  onError: (error) => {},
});

export const fetchMeetingList = createApiThunk({
  name: "list/fetchMeetingList",
  endpoint: `meeting/meeting-list/`,
  onSuccess: (data) => {},
  onError: (error) => {},
});

export const post = createApiThunk({
  name: "list/fetchMeetingList",
  endpoint: `meeting/meeting-list/`,
  method: "POST",
  onSuccess: (data) => {},
  onError: (error) => {},
});

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setTaskerId: (state: IInitialState, { payload }: PayloadAction<number>) => {
      state.taskerId = payload;
    },
    setDirectorId: (
      state: IInitialState,
      { payload }: PayloadAction<number>
    ) => {
      state.directorId = payload;
    },
    setTitle: (state: IInitialState, { payload }: PayloadAction<string>) => {
      state.title = payload;
    },
    setStartTime: (state: IInitialState, { payload }: PayloadAction<Date>) => {
      state.start_time = payload;
    },
    setEndTime: (state: IInitialState, { payload }: PayloadAction<Date>) => {
      state.end_time = payload;
    },
    setRepeat: (state: IInitialState, { payload }: PayloadAction<number>) => {
      state.repeat = payload;
    },
    setGoalTitle: (
      state: IInitialState,
      { payload }: PayloadAction<string>
    ) => {
      state.goalTitle = payload;
    },
    setTasks: (state: IInitialState, { payload }: PayloadAction<number>) => {
      state.tasks = state?.tasks?.filter((item: any) => item.id !== payload);
    },
    setEvents: (state: IInitialState, { payload }: PayloadAction<any>) => {
      state.meetings = payload;
    },
    setCompany: (state, action) => {
      // console.log(action.payload);
      state.selectedCompany = action.payload;
    },
    deleteTaskContainer: (state, action) => {
      const id = action.payload;
      // console.log(state.tasks);
      state.tasks = state.tasks.map((item) => {
        return {
          ...item,
          tasks: item.tasks.filter((elem: any) => elem.id !== id),
        };
      });
      state.folders = state.tasks.map((item) => {
        return {
          ...item,
          tasks: item.tasks.filter((elem: any) => elem.id !== id),
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyDirection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCompanyDirection.fulfilled,
      (state, action: PayloadAction<Array<IDirection>>) => {
        state.loading = false;
        state.company_direction = action.payload;
      }
    );
    builder.addCase(getCompanyDirection.rejected, (state, action) => {
      state.loading = false;
      state.company_direction = [];
      //   state.error = action.error.message;
    });

    builder.addCase(getCompanySubDirection.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCompanySubDirection.fulfilled,
      (state, action: PayloadAction<Array<IDirection>>) => {
        state.loading = false;
        state.sub_company_direction = action.payload;
      }
    );
    builder.addCase(getCompanySubDirection.rejected, (state, action) => {
      state.loading = false;
      state.sub_company_direction = [];
      // state.error = action.error.message;
    });

    builder.addCase(getAllStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllStaff.fulfilled,
      (state, action: PayloadAction<Array<IEachStaff>>) => {
        state.loading = false;
        state.all_staff = action.payload;
      }
    );
    builder.addCase(getAllStaff.rejected, (state, action) => {
      state.loading = false;
      state.sub_company_direction = [];
      // state.error = action.error.message;
    });

    builder.addCase(getSctuctureCompany.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSctuctureCompany.fulfilled,
      (state, action: PayloadAction<Array<IEachStaff>>) => {
        state.loading = false;
        state.structureOfCompany = action.payload;
      }
    );
    builder.addCase(getSctuctureCompany.rejected, (state, action) => {
      state.loading = false;
      state.structureOfCompany = {};
    });

    builder.addCase(getGoalsFolder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGoalsFolder.fulfilled,
      (state, action: PayloadAction<Array<IGoalsFolder>>) => {
        state.loading = false;
        state.goals_folder = action.payload;
      }
    );
    builder.addCase(getGoalsFolder.rejected, (state, action) => {
      state.loading = false;
      state.goals_folder = [];
    });

    builder.addCase(getGoals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGoals.fulfilled,
      (state, action: PayloadAction<Array<IGoalsFolder>>) => {
        state.loading = false;
        state.goals = action.payload;
      }
    );
    builder.addCase(getGoals.rejected, (state, action) => {
      state.loading = false;
      state.goals = [];
    });

    builder.addCase(getPeriodBy.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getPeriodBy.fulfilled,
      (state, action: PayloadAction<Array<IGoalsFolder>>) => {
        state.loading = false;
        state.events = action.payload || [];
        state.meetings = action.payload;
        // console.log(action.payload);
      }
    );
    builder.addCase(getPeriodBy.rejected, (state, action) => {
      state.loading = false;
      state.events = [];
    });

    builder.addCase(getTasks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getTasks.fulfilled,
      (state, action: PayloadAction<Array<IGoalsFolder>>) => {
        state.loading = false;
        state.tasks = action.payload;
      }
    );
    builder.addCase(getTasks.rejected, (state, action) => {
      state.loading = false;
      state.tasks = [];
    });
    // postNewTaskFolder
    builder.addCase(postNewTaskFolder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      postNewTaskFolder.fulfilled,
      (state, action: PayloadAction<Array<IGoalsFolder>>) => {
        state.loading = false;
        state.tasks.unshift(action.payload);
      }
    );
    builder.addCase(postNewTaskFolder.rejected, (state, action) => {
      state.loading = false;
      state.tasks = [];
    });

    builder.addCase(getNotifications.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getNotifications.fulfilled,
      (state, action: PayloadAction<Array<INotification>>) => {
        state.loading = false;
        state.notificationList = action.payload;
      }
    );
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.loading = false;
      state.notificationList = [];
    });

    builder.addCase(getProjectList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getProjectList.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.projects = action.payload;
      }
    );
    builder.addCase(getProjectList.rejected, (state, action) => {
      state.loading = false;
      state.projects = [];
    });

    builder.addCase(getFolders.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getFolders.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        console.log(action.payload);
        state.loading = false;
        state.folders = action.payload;
        state.tasks = action.payload;
      }
    );
    builder.addCase(getFolders.rejected, (state, action) => {
      state.loading = false;
      state.folders = [];
    });
    // fetchDatingList
    builder.addCase(fetchDatingList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchDatingList.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        // console.log(action.payload);
        state.loading = false;
        state.datingList = action.payload;
      }
    );
    builder.addCase(fetchDatingList.rejected, (state, action) => {
      state.loading = false;
      state.datingList = [];
    });

    builder.addCase(fetchMeetingList.pending, (state) => {
      state.secondLoading = true;
    });
    builder.addCase(
      fetchMeetingList.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        // console.log(action.payload);
        state.secondLoading = false;
        state.meetings = action.payload;
      }
    );
    builder.addCase(fetchMeetingList.rejected, (state, action) => {
      state.secondLoading = false;
      state.meetings = [];
    });
  },
});

export const selectCompany = (state: TypeStore) => state.company;

export const {
  setTaskerId,
  setDirectorId,
  setTitle,
  setStartTime,
  setEndTime,
  setRepeat,
  setGoalTitle,
  setTasks,
  setEvents,
  setCompany,
  deleteTaskContainer,
} = slice.actions;

export default slice.reducer;
