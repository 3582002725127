import { createAsyncThunk } from '@reduxjs/toolkit';
import { CalendarEvenetService } from '@src/services';
import { ICreateEvenetPay, IGetOneEvenetResponce, IUpdateEvenetPay } from './types';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

export const getOneEvenet = createAsyncThunk('evenet/getOne', async (id: number) => {
	const responce = await CalendarEvenetService.getOneEvenet(id);
	return { data: responce } as AxiosResponse;
});

export const updateMeetingEvenet = createAsyncThunk<IGetOneEvenetResponce, IUpdateEvenetPay>('event/update', async ({ meet, id }, thunkApi) => {
	try {
		const responce = await CalendarEvenetService.updateMeetEvenet(meet, id);
		if (responce.data) {
			toast.success('Event succesfully updated');
		}
		return responce.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

export const createMeetingEvenet = createAsyncThunk<IGetOneEvenetResponce, ICreateEvenetPay>('event/update', async ({ meet }, thunkApi) => {
	try {
		const responce = await CalendarEvenetService.createMeetEvent(meet);
		if (responce.data) {
			toast.success('Event succesfully updated');
		}
		return responce.data;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});

// export const getOneProduct = createAsyncThunk<IOneProductResponse, IOneProductPayload>('one/product', async ({ productId, callback }, thunkApi) => {
// 	try {
// 		const response = await ProductService.getOneProduct(productId);
// 		if (response.data.status == 200) {
// 			callback();
// 		}
// 		return response.data;
// 	} catch (error) {
// 		addNotification(error);
// 		return thunkApi.rejectWithValue({ error: errorCatch(error) });
// 	}
// });
