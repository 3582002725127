import { combineReducers } from '@reduxjs/toolkit';
import ui from './ui';
import auth from './auth/auth';
import company from './company/company';
import staff from './staff/staff';
import event from './company/event/slices';
import note from './company/note/slices';
import task from './company/task/slices';
import assambly from './company/assambly/slices';
import plan from './quickPlan/quickPlan';
import idea from './quickIdea/quickIdea'

export default combineReducers({
	ui,
	auth,
	company,
	staff,
	event,
	note,
	task,
	assambly,
	plan,
	idea
});

export * from './ui';
