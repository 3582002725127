import { TypeStore } from './../../config';
import { createSelector } from '@reduxjs/toolkit';
import { IUser } from '@src/types/auth';


export const getUser = createSelector(
    (store: TypeStore) => store.auth.user,
    (user: IUser) =>{
        return user
    }
)


