import { axiosService } from '@src/lib';
import { API } from '../Endipoinds';
import { IEvenetData, IMeetCreateData } from '../../store/reducer-and-action/company/event/types';
import { convertStringToDate } from '@src/utils';
export const CalendarEvenetService = {
	async getOneEvenet(id: number) {
		const response = await axiosService.get(`${API.getOneEvenet}/${id}`);
		return response;
	},

	async updateMeetEvenet(meet: IEvenetData, id: number) {
		const { title, description, address, start_time, end_time, importance, repeat, status } = meet;
		const opt = {
			title,
			description,
			address,
			start_time: convertStringToDate(start_time),
			end_time: convertStringToDate(end_time),
			importance,
			repeat,
			until_date: '2024-01-29T15:50:46.080Z',
			status,
			is_editable: true,
		};
		const responce = await axiosService.put(`${API.updateMeetEvenet}/${id}`, opt);
		return responce;
	},

	async createMeetEvent(meet: IMeetCreateData) {
		// const { title, description, address, start_time, end_time, importance, repeat, status } = meet;
		// const opt = {
		// 	title,
		// 	description,
		// 	address,
		// 	start_time,
		// 	end_time,
		// 	importance,
		// 	repeat,
		// 	until_date: '2024-01-29T15:50:46.080Z',
		// 	status,
		// 	is_editable: true,
		// };
		const responce = await axiosService.post(API.createMeetEvenet, { ...meet, members: [], until_date: '2024-01-29T15:50:46.080Z' });
		return responce;
	},
};
