import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseUrl } from "@src/data/baseUrl";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface ApiThunkOptions {
  name: string;
  endpoint: string;
  method?: string;
  initialState?: any[];
  onSuccess?: (data: any) => void;
  onError?: (error: string) => void;
}

export const createApiThunk = ({
  name,
  endpoint,
  method = "GET",
  initialState = [],
  onSuccess,
  onError,
}: ApiThunkOptions) => {
  return createAsyncThunk(name, async (payload: any, { rejectWithValue }) => {
    console.log(`payload`, payload);
    try {
      const response = await fetch(`${BaseUrl}${endpoint}`, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${cookies.get("access_token")}`,
        },
        // Include payload in the body if it's a POST request, for example
        body: method === "POST" ? JSON.stringify(payload) : undefined,
      });

      if (!response.ok) {
        // Handle non-2xx status codes
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      // Call onSuccess callback if provided
      onSuccess && onSuccess(data);

      return data;
    } catch (error: any) {
      // Call onError callback if provided
      onError && onError(error.message);

      // Return an object with an error property for rejection
      return rejectWithValue({ error: error.message });
    }
  });
};
