import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "@src/data/baseUrl";
import { TypeStore } from "@src/store/config";
import Cookies from "universal-cookie";
import { IInitialState, ISection } from "./staff.t";
import { toast } from "react-toastify";

const cookies = new Cookies();

export const getShortUsersFavorite = createAsyncThunk(
  "staff/getShortUsersFavorite",
  async (thunkAPI) => {
    let shortUsersFavoriteUrl = `${BaseUrl}company/short-user-favourites/`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(shortUsersFavoriteUrl, requestOptions).then(
      (data) => data.json()
    );
    return res.data;
  }
);

export const getList = createAsyncThunk("list/getList", async (thunkAPI) => {
  let listUrl = `${BaseUrl}company/short-structure/`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${cookies.get("access_token")}`,
    },
  };

  const res = await fetch(listUrl, requestOptions).then((data) => data.json());
  // if (!!res?.detail) {
  //   toast.error(res?.detail);
  // }
  // console.log(`res`, res);
  return res?.data?.data_list;
});

export const getShortAllStaff = createAsyncThunk(
  "list/getShortAllStaff",
  async (thunkAPI) => {
    let staffUrl = `${BaseUrl}company/short-all-staffs/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(staffUrl, requestOptions).then((data) =>
      data.json()
    );

    return res.data;
  }
);

export const getOutSourseList = createAsyncThunk(
  "list/getOutSourseList",
  async (thunkAPI) => {
    let staffUrl = `${BaseUrl}user/outsource-list`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(staffUrl, requestOptions).then((data) =>
      data.json()
    );

    return res.data;
  }
);

export const getAttechingSections = createAsyncThunk(
  "list/getAttechingSections",
  async (thunkAPI) => {
    let staffUrl = `${BaseUrl}company/structure/`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(staffUrl, requestOptions).then((data) =>
      data.json()
    );

    return res.data.data_list;
  }
);

export const postNewSection = createAsyncThunk(
  "section/postNewSection",
  async (newSection: ISection, thunkAPI) => {
    let sectionUrl = `${BaseUrl}company/department-create/`;
    const { handleClose, ...newObj } = newSection;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(newObj),
    };

    const res = await fetch(sectionUrl, requestOptions).then((data) =>
      data.json()
    );

    if (!res.success) {
      res?.error?.forEach((item: any) => {
        toast.error(`${item.error} => ${item.key}`);
      });
    }
    if (res.success) {
      toast.success(res.message);
      handleClose();
    }

    return res;
  }
);

export const getAllPermissions = createAsyncThunk(
  "permissions/getAllPermissions",
  async (thunkAPI) => {
    let permissionsUrl = `${BaseUrl}company/all-permissions`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };

    const res = await fetch(permissionsUrl, requestOptions).then((data) =>
      data.json()
    );

    return res.data;
  }
);

export const createNewPosition = createAsyncThunk(
  "position/createNewPosition",
  async (newPosition: any, thunkAPI) => {
    let sectionUrl = `${BaseUrl}company/position-create/`;
    const { handleClose, ...newObj } = newPosition;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify(newObj),
    };

    // console.log(requestOptions);

    const res = await fetch(sectionUrl, requestOptions).then((data) =>
      data.json()
    );

    if (!res.success) {
      res?.error?.forEach((item: any) => {
        toast.error(`${item.error} => ${item.key}`);
      });
    }
    if (res.success) {
      toast.success(res.message);
      handleClose();
    }

    return res;
  }
);

const initialState: IInitialState = {
  loading: false,
  users: [],
  data_list: [],
  all_staff: [],
  outsource_list: [],
  section_list: [],
  all_permissions: [],
};

const slice = createSlice({
  name: "staff",
  initialState,
  reducers: {
    // setTaskerId: (state: IInitialState, { payload }: PayloadAction<number>) => {
    //   state.taskerId = payload;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(getShortUsersFavorite.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getShortUsersFavorite.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.users = action.payload;
      }
    );
    builder.addCase(getShortUsersFavorite.rejected, (state, action) => {
      state.loading = false;
      state.users = [];
    });

    builder.addCase(getList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getList.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.data_list = action.payload;
      }
    );
    builder.addCase(getList.rejected, (state, action) => {
      console.log("ERROR_ERROR");
      state.loading = false;
      state.data_list = [];
    });

    builder.addCase(getShortAllStaff.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getShortAllStaff.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.all_staff = action.payload;
      }
    );
    builder.addCase(getShortAllStaff.rejected, (state, action) => {
      state.loading = false;
      state.all_staff = [];
    });

    builder.addCase(getOutSourseList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getOutSourseList.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.outsource_list = action.payload;
      }
    );
    builder.addCase(getOutSourseList.rejected, (state, action) => {
      state.loading = false;
      state.outsource_list = [];
    });

    builder.addCase(getAttechingSections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAttechingSections.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.section_list = action.payload;
      }
    );
    builder.addCase(getAttechingSections.rejected, (state, action) => {
      state.loading = false;
      state.section_list = [];
    });

    builder.addCase(getAllPermissions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllPermissions.fulfilled,
      (state, action: PayloadAction<Array<any>>) => {
        state.loading = false;
        state.all_permissions = action.payload;
      }
    );
    builder.addCase(getAllPermissions.rejected, (state, action) => {
      state.loading = false;
      state.all_permissions = [];
    });
  },
});

export const selectStaff = (state: TypeStore) => state.staff;

// export const {} = slice.actions;

export default slice.reducer;
