import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BaseUrl } from "@src/data/baseUrl";
import { TypeStore } from "@src/store/config";
import Cookies from "universal-cookie";
import { IQuickPlan, IInitialState, IQuickPlanRetrieve } from "./quickPlan.t";

const cookies = new Cookies();
const initialState: IInitialState = {
  quick_plan: [],
  quick_plan_retrieve: [],

  dayName: "week",
  date: {},
  id: null,
  loading: false,
  error: "",
};

export const getQuickPlan = createAsyncThunk(
  "quickPlan/getQuickPlan",
  async ({ name, date }: { name: string; date: any }, thunkAPI) => {
    let quickPlanUrl = `${BaseUrl}quick-plan/quick-plan-list${name}/?${date}`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };
    const res = await fetch(quickPlanUrl, requestOptions)
      .then((data) => data.json())
      .catch(() => "error");
    return res;
  }
);

export const getQuickPlanRetrieve = createAsyncThunk(
  "quickPlanRetrieve/getQuickPlanRetrieve",
  async (id: number, thunkAPI) => {
    let quickPlanRetrieveUrl = `${BaseUrl}quick-plan/quick-plan-retrieve/${id}/`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };
    const res = await fetch(quickPlanRetrieveUrl, requestOptions)
      .then((data) => data.json())
      .catch(() => "error");
    return res;
  }
);

export const deleteQuickPlan = createAsyncThunk(
  "quickPlan/deleteQuickPlan",
  async (id: number, thunkAPI) => {
    let deleteQuickPlanUrl = `${BaseUrl}quick-plan/quick-plan-delete/${id}/`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
    };
    const res = await fetch(deleteQuickPlanUrl, requestOptions)
      .then((data) => data.json())
      .catch(() => "error");
    return res;
  }
);

export const patchQuickPlan = createAsyncThunk(
  "quickPlan/patchQuickPlan",
  async ({ id, is_done }: { id: number; is_done: boolean }, thunkAPI) => {
    let quickPlanUrl = `${BaseUrl}quick-plan/quick-plan-update/${id}/`;
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify({
        is_done: is_done,
      }),
    };
    const res = await fetch(quickPlanUrl, requestOptions)
      .then((data) => data.json())
      .catch(() => "error");
    return res;
  }
);

export const postQuickPlan = createAsyncThunk(
  "quickPlan/postQuickPlan",
  async ({ name, date }: { name: string; date: string }, thunkAPI) => {
    let quickPlanUrl = `${BaseUrl}quick-plan/quick-plan/`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("access_token")}`,
      },
      body: JSON.stringify({
        title: name,
        date: date,
      }),
    };
    const res = await fetch(quickPlanUrl, requestOptions)
      .then((data) => data.json())
      .catch(() => "error");
    return res;
  }
);
const slice = createSlice({
  name: "plan",
  initialState,
  reducers: {
    changeDayName: (state, action) => {
      state.dayName = action.payload;
    },
    changeId: (state, action) => {
      state.id = action.payload;
    },
    changeDate: (state, action) => {
      state.date = action.payload;
    },
    changeIsDone: (state, action) => {
      const toggleIsDoneRecursively = (item: any, id: number) => {
        if (item.id === id) {
          return { ...item, is_done: !item.is_done };
        }

        if (item.children) {
          return {
            ...item,
            children: item.children.map((child: any) =>
              toggleIsDoneRecursively(child, id)
            ),
          };
        }

        if(item.id === id && item.is_done){
          item?.children.map((item: any)=>{
            return {
              ...item, is_done: true
            }
          })
        }

        return item;
      };

      const isPageOne = state.dayName !== "page=1";
      const todo: any = state.quick_plan;
      const updatedQuickPlan = isPageOne
        ? todo.map((item: any) => toggleIsDoneRecursively(item, action.payload))
        : {
            ...state.quick_plan,

            results: todo.results.map((item: any) =>
              toggleIsDoneRecursively(item, action.payload)
            ),
          };

      state.quick_plan = updatedQuickPlan;
    },
    deletePlan: (state, action) => {
      const deleteRecursively = (item: any, id: number) => {
        if (item.id === id) {
          return null;
        }

        if (item.children) {
          return {
            ...item,
            children: item.children
              .map((child: any) => deleteRecursively(child, id))
              .filter(Boolean),
          };
        }

        return item;
      };

      const isPageOne = state.dayName !== "page=1";

      const todo: any = state.quick_plan;
      const deleteQuickPlan = isPageOne
        ? todo
            .map((item: any) => deleteRecursively(item, action.payload))
            .filter(Boolean)
        : {
            ...state.quick_plan,

            results: todo.results
              .map((item: any) => deleteRecursively(item, action.payload))
              .filter(Boolean),
          };

      state.quick_plan = deleteQuickPlan;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuickPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getQuickPlan.fulfilled,
        (state, action: PayloadAction<Array<IQuickPlan>>) => {
          state.loading = false;
          state.quick_plan = action.payload;
          //   console.log('done', action.payload);
        }
      )
      .addCase(getQuickPlan.rejected, (state, action) => {
        state.loading = false;
        state.quick_plan = [];
      });

      builder
      .addCase(postQuickPlan.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        postQuickPlan.fulfilled,
        (state, action: PayloadAction<Array<IQuickPlan>>) => {
          state.loading = false;
          if (state.dayName !== "page=1") {
            let todo: any = state.quick_plan;
            todo = [...todo, action.payload];
            state.quick_plan = todo;
          } else {
            let todo: any = state.quick_plan;
            let todo1 = [...todo.results, action.payload];
            todo.results = todo1;
            state.quick_plan = todo;
          }
        }
      )
      .addCase(postQuickPlan.rejected, (state, action) => {
        state.loading = false;
        state.quick_plan = [];
      });
    builder
      .addCase(getQuickPlanRetrieve.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getQuickPlanRetrieve.fulfilled,
        (state, action: PayloadAction<Array<IQuickPlanRetrieve>>) => {
          state.loading = false;
          state.quick_plan_retrieve = action.payload;
          //   console.log('done', action.payload);
        }
      )
      .addCase(getQuickPlanRetrieve.rejected, (state, action) => {
        state.loading = false;
        state.quick_plan_retrieve = [];
      });
  },
}) as any;

export const selectQuickPlan = (state: TypeStore) => state.plan;

export const {
  changeDayName,
  changeDate,
  changeIsDone,
  changeId,
  deletePlan,
} = slice.actions;

export default slice.reducer;
