import { createAsyncThunk } from '@reduxjs/toolkit';
import { NoteService } from '@src/services/calendar-event/note.services';
import { IGetOneNotePay, IGetOneNoteResponce } from './types';

export const getOneNote = createAsyncThunk<IGetOneNoteResponce, IGetOneNotePay>('getone/note', async ({ id }, thunkApi) => {
	try {
		const responce = await NoteService.getOneNote(id);
		return { data: responce.data } as IGetOneNoteResponce;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
