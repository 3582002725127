import { axiosService } from '@src/lib';
import { API } from '../Endipoinds';

export const TaskService = {
	async getOneTask(id: number) {
		const responce = await axiosService.get(`${API.getOnetask}/${id}`);
		return responce.data;
	},

	async createTask(data: any) {
		const responce = await axiosService.post(API.createTask, data);
		return responce;
	},
};
