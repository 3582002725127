import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './types';
import { getOneEvenet } from './actions';

const initialState: InitialState = {
	loading: {
		get: false,
		post: false,
		put: false,
		delete: false,
	},
	error: null,
	events: null,
	event: null,
	currentEvenet: {
		id: 0,
		creator: {
			id: 0,
			full_name: '',
			image: '',
		},
		title: '',
		description: '',
		start_time: '',
		end_time: '',
		members: [
			{
				id: 0,
				state: '',
				description: null,
				user: {
					id: 0,
					full_name: '',
					image: '',
				},
				moderator: false,
				participated: null,
			},
		],
		files: [],
		address: '',
		importance: '',
		repeat: '',
		repeat_rule: 0,
		reminders: [0],
		member_state: null,
		until_date: '2024-01-29T15:50:46.080Z',
		created_at: '',
		status: '',
		discussed_topics: [],
		is_editable: true,
		commentaries: [],
	},
	newEvent: {
		title: '',
		description: '',
		start_time: '',
		end_time: '',
		address: '',
		importance: '',
		repeat: '',
		members: [],
		repeat_rule: 0,
		until_date: '',
		status: '',
		message_id: 0,
		files: [],
		reminders: [],
		discussed_topics: [],
		moderators: [],
		new_reminders: [],
	},
};

export const eventSlice = createSlice({
	name: 'getOne/evenet',
	initialState,
	reducers: {
		setFullName: (state, { payload }) => {
			state.currentEvenet.creator.full_name = payload;
		},

		setAdress: (state, { payload }) => {
			state.currentEvenet.address = payload;
		},

		setDescription: (state, { payload }) => {
			state.currentEvenet.description = payload;
		},

		setStartTime: (state, { payload }) => {
			state.currentEvenet.start_time = payload;
		},

		setEndTime: (state, { payload }) => {
			state.currentEvenet.end_time = payload;
		},
	},
	extraReducers: builder => {
		builder
			.addCase(getOneEvenet.pending, state => {
				state.loading.get = true;
			})
			.addCase(getOneEvenet.fulfilled, (state, { payload }) => {
				state.loading.get = false;
				state.event = payload.data;
				state.currentEvenet = payload.data;
			})
			.addCase(getOneEvenet.rejected, (state, { payload }) => {
				state.loading.get = false;
				state.error = payload as string;
			});
	},
});
export const { setStartTime, setAdress, setDescription, setEndTime, setFullName } = eventSlice.actions;
export const EvenetSliceActions = eventSlice.actions;
export const getEvenet = (state: any) => state.event as InitialState;
export default eventSlice.reducer;
