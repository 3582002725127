import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "@src/store/config";
import App from "./App";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import uzTranslation from "./locales/uz.json";
import ruTranslation from "./locales/ru.json";
import enTranslation from "./locales/en.json";

export function getCurrentLanguage(): string {
  let currentLanguage: string =
    JSON.parse(localStorage.getItem("currentLanguage") as string) || `uz`;
  return currentLanguage;
}

i18n.use(initReactI18next).init({
  resources: {
    uz: {
      translation: uzTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
    en: {
      translation: enTranslation,
    },
  },
  lng: getCurrentLanguage(), // Default language
  fallbackLng: "uz", // Fallback language
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;

const client = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <QueryClientProvider client={client}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </Provider>
  </>
);
reportWebVitals();
