import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsamblyService } from '@src/services/calendar-event/asambly.service';
import { IGetOneAssamblyPay, IGetOneAssamblyResponce } from './types';

export const getOneAssambly = createAsyncThunk<IGetOneAssamblyResponce, IGetOneAssamblyPay>('getone/assambly', async ({ id }, thunkApi) => {
	try {
		const responce = await AsamblyService.getOneAsambly(id);
		return { data: responce.data } as IGetOneAssamblyResponce;
	} catch (error) {
		return thunkApi.rejectWithValue(error);
	}
});
