export const API = {
	// calenedar-evenet
	createMeetEvenet: '/meeting/meeting',
	getOneEvenet: '/meeting/meeting-retrieve',
	updateMeetEvenet: '/meeting/meeting-update',
	deleteMeetEvent: '/meeting/meeting-delete',

	//calendar-task
	getOnetask: '/task/task-retrieve',
	createTask: '/task/task',
	updateTask: '/task/task-update',
	deleteTask: '/task/task-delete',

	// calendar-note
  getOneNote:'/notes/note-retrieve',

	// calendar-assambly
	getOneAsambly:'/dating/dating-retrieve',

	// chat
	postAnImage: 'chat/personal-chat/file-upload',
};
