import { ComponentType, lazy, LazyExoticComponent, ReactNode } from "react";

export interface IRoute {
  // Path, like in basic prop
  path: string;
  // Exact, like in basic prop
  exact: boolean;
  // Preloader for lazy loading
  fallback?: NonNullable<ReactNode> | null;
  // Lazy Loaded component
  component?: LazyExoticComponent<ComponentType<any>>;
  // Sub routes
  routes?: IRoute[];
  // Redirect path
  redirect?: string;
  // If router is private, this is going to be true
  private?: boolean;
}

export const routes = (selectedCompany: any): IRoute[] => {
  return [
    {
      path: "/",
      redirect: "/companies",
      exact: true,
      private: false,
    },
    {
      path: "/auth",
      component: lazy(() => import("../components/Pages/auth-page/auth-page")),
      exact: true,
      private: false,
    },
    {
      path: "/dashboard",
      component: lazy(
        () => import("../components/Pages/dashboard-page/dashboard-page")
      ),
      exact: true,
      private: false,
    },
    {
      path: "/chats/company=:id",
      component: lazy(() => import("@components/Pages/chats-page/chats-page")),
      exact: true,
      private: false,
    },
    {
      path: "/calendar",
      component: lazy(
        () => import("@components/Pages/calendar-page/calendar-page")
      ),
      exact: true,
      private: false,
    },
    {
      path: "/tasks",
      component: lazy(() => import("@components/Pages/tasks-page/tasks-page")),
      exact: true,
      private: false,
    },
    {
      path: "/meetings",
      component: lazy(
        () => import("@components/Pages/meetings-page/meetings-page")
      ),
      exact: true,
      private: false,
    },
    {
      path: "/staff",
      component: lazy(() => import("@components/Pages/staff-page/staff-page")),
      exact: true,
      private: false,
    },
    {
      path: "/companies",
      component: lazy(
        () => import("@components/Pages/companies-page/companies-page")
      ),
      exact: true,
      private: selectedCompany?.title === "No company" ? false : true,
    },
    {
      path: "/target-map",
      component: lazy(() => import("@components/Pages/target-map/target-map")),
      exact: true,
      private: false,
    },
    {
      path: "/suggestion",
      component: lazy(
        () => import("@components/Pages/suggestion-page/suggestion-page")
      ),
      exact: true,
      private: false,
    },
    {
      path: "/year",
      component: lazy(
        () => import("@components/common/year-calendar/year-calendar")
      ),
      exact: true,
      private: false,
    },
    {
      path: "/profile",
      component: lazy(
        () => import("../components/Pages/profile-page/profile-page")
      ),
      exact: true,
      private: false,
    },
    {
      path: "/premium",
      component: lazy(
        () => import("../components/Pages/premium-page/premium-page")
      ),
      exact: true,
      private: false,
    },
  ];
};
